import { Table } from "antd";
import styled from "styled-components";
export const TableHeader = styled.div`
  display: flex;
  justify-content: spce-between;
  width: 100%;
`;
export const AddUserButton = styled.span`
  color: #2563dd;
  font-family: SF Pro Text;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 5px;
  display: flex;
`;

export const StyledTable = styled(Table)`
  th {
    background-color: #fff !important;
    font-weight: 500 !important;
  }
`;
export const ExcelButton = styled.span`
  color: #185c37;
  font-family: SF Pro Text;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 5px;
  display: flex;
`;
export const More = styled.div`
  text-align: center;
  margin: 20px;
  color: #141414;
  text-align: center;
  font-family: SF Pro Text;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 214.286% */
`;
export const ItemTitle = styled.div`
  color: #6f7273;
  font-family: SF Pro Text;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-right: 10px;
`;
export const ItemNumber = styled.div`
  display: flex;
  width: 89px;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 8px;
  background: ${(props) => props.background};
`;
