import styled from "styled-components";

export const BackDiv = styled.div`
  display: flex;
  color: #141414;
  leading-trim: both;
  text-edge: cap;
  font-family: SF Pro Text;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.6px;
  margin-bottom: 20px;
`;
