import { useParams } from "react-router-dom";
import customFetch from "../../services/api";

import { useEffect, useState } from "react";
import HeaderComponent from "../shared/header";
import FooterComponent from "../shared/footer";
import HeaderListsComponent from "../shared/header-lists";
import { Col, Row, Tag, Avatar } from "antd";
import { Input, Select } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { DivCenter, ImageDiv, ImageTitle } from "../shared/style";
import { useNavigate } from "react-router-dom";
import { BackDiv } from "./style";
import ProfileCard from "../shared/profile-card";
import { FormButton } from "../registration/style";
const { Search } = Input;

const Guest = () => {
  const [guest, setGuest] = useState([]);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/guests");
  };
  useEffect(() => {
    const fetchParticipant = async () => {
      setLoading(true);
      const response = await customFetch("/participant/" + id);
      if (response.success) {
        setGuest(response.data);
      }
      setLoading(false);
    };
    fetchParticipant();
  }, []);
  return (
    <>
      <HeaderComponent />
      <HeaderListsComponent />
      <ImageDiv>
        <ImageTitle>Profile</ImageTitle>
      </ImageDiv>

      <div style={{ margin: "78px 5%" }}>
        <BackDiv onClick={handleBack}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="24"
            viewBox="0 0 32 24"
            fill="none"
          >
            <path
              d="M4.25 12.2744L30.5 12.2744"
              stroke="#141414"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10.2998 18.2988L4.24981 12.2748L10.2998 6.24976"
              stroke="#141414"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          {"  "}{" "}
          <div style={{ marginLeft: "10px", marginTop: "3px" }}>Back</div>
        </BackDiv>
      </div>
      <div style={{ maxWidth: "810px", margin: "0px auto" }}>
        <Row>
          <Col xs={24} sm={12}>
            <ProfileCard label="First Name" value={guest?.first_name} />
          </Col>
          <Col xs={24} sm={12}>
            <ProfileCard label="Middle Name" value={guest?.middle_name} />
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={12}>
            <ProfileCard label="Family Name" value={guest?.family_name} />
          </Col>
          <Col xs={24} sm={12}>
            <ProfileCard label="Email" value={guest?.email} />
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={12}>
            <ProfileCard label="Mobile" value={guest?.mobile} />
          </Col>
          <Col xs={24} sm={12}>
            <ProfileCard label="Nationality" value={guest?.nationality} />
          </Col>
        </Row>
        <Row justify="center" style={{ marginTop: "50px" }}>
          <Col xs={10}>
            <FormButton>Save</FormButton>
          </Col>
        </Row>
      </div>
      <FooterComponent />
    </>
  );
};
export default Guest;
