import { ProfCard, ProfCardLabel, ProfCardValue } from "./style";

const ProfileCard = ({ label, value }) => {
  return (
    <>
      <ProfCard>
        <ProfCardLabel>{label}</ProfCardLabel>
        <ProfCardValue>{value}</ProfCardValue>
      </ProfCard>
    </>
  );
};
export default ProfileCard;
