import { StatiscsCard, Title, Value } from "./style";

const StatiscsCardComponent = ({ title, value }) => {
  return (
    <StatiscsCard>
      <Title>
        {title}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M8 8L8 11M8 5.77637V5.75M2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8Z"
            stroke="#AFAFAF"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </Title>
      <Value>{value}</Value>
    </StatiscsCard>
  );
};
export default StatiscsCardComponent;
