import {
  DescriptionContainer,
  Header,
  HeaderDescription,
  HeaderTitle,
  ImageDiv,
  TermsButton,
} from "./style";
import { Form, message } from "antd";
import { useState } from "react";
import customFetch from "../../services/api";
import FormComponent from "./form";
import { useNavigate } from "react-router-dom";
import FooterComponent from "../shared/footer";

const Registration = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const onFinish = async (values) => {
    setLoading(true);
    const response = await customFetch("/participants", {
      method: "POST",
      body: values,
    });
    if (response.success) {
      message.success({
        content: response.message,
        duration: 2,
      });
      navigate("/registration-success");
    } else {
      form.setFields([
        {
          name: "email",
          value: values.email,
          errors: response.errorMessage.email,
        },
        {
          name: "mobile",
          value: values.mobile,
          errors: response.errorMessage.mobile,
        },
      ]);
    }
    setLoading(false);
  };
  return (
    <>
      <Header>
        <div style={{ marginLeft: "5%" }}>
          <img
            src="iptc-logo.jpg"
            alt="logo"
            width="166.56px"
            height="69.72px"
          />
        </div>
        <TermsButton>Terms & Conditions</TermsButton>
      </Header>
      <ImageDiv />
      <DescriptionContainer>
        <HeaderTitle>Registration</HeaderTitle>
        <HeaderDescription>
          This essential document captures key details, creating a personalized
          connection between
        </HeaderDescription>
        <HeaderDescription>organizers and participants</HeaderDescription>
      </DescriptionContainer>
      <div style={{ maxWidth: "970px", margin: "100px auto" }}>
        <FormComponent onFinish={onFinish} form={form} />
      </div>
      <FooterComponent />
    </>
  );
};
export default Registration;
