import { Header, HeaderItems, StyledNumber } from "./style";
import { Input } from "antd";
const { Search } = Input;
const HeaderComponent = () => {
  const onSearch = () => {};
  return (
    <Header>
      <div style={{ marginLeft: "5%" }}>
        <img
          src="../iptc-logo.jpg"
          alt="logo"
          width="166.56px"
          height="69.72px"
        />
      </div>
      <HeaderItems>
        <Search
          placeholder="input search text"
          onSearch={onSearch}
          style={{
            width: 200,
          }}
        />
        <img alt="" src="../chartButton.jpg" width="34px" height="34px" />
        <img alt="" src="../avatar.jpg" width="34px" height="34px" />
        <StyledNumber>3</StyledNumber>
      </HeaderItems>
    </Header>
  );
};
export default HeaderComponent;
