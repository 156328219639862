import { Input, Select } from "antd";
import styled from "styled-components";
export const Header = styled.div`
  height: 155px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const TermsButton = styled.button`
  background-color: #78a22e;
  color: #fff;
  width: 208px;
  height: 48px;
  text-align: center;
  border-radius: 6px;
  border: none;
  margin-right: 5%;
  @media (max-width: 768px) {
    width: 150px;
  }
`;
export const ImageDiv = styled.div`
  width: 100%;
  height: 124px;
  background-image: url(img-header-1.jpeg);
  background-size: cover;
`;
export const DescriptionContainer = styled.div`
  background-color: #78486a;
  height: 260px;
  gap: 23px;
`;
export const HeaderTitle = styled.div`
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 44px;
  font-style: normal;
  font-weight: 400;
  line-height: 56px; /* 127.273% */
  letter-spacing: -0.88px;
  padding-top: 57px;
`;
export const HeaderDescription = styled.p`
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 300;
  line-height: 30px; /* 176.471% */
  letter-spacing: -0.17px;
`;
export const FormContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
`;
export const FormButton = styled.button`
  width: 100%;
  height: 40px;
  text-align: center;
  background: var(--78-a-22-e, #78a22e);
  border: none;
  color: #fff;
  border-radius: 6px;
`;
export const StyledInput = styled(Input)`
  border-radius: 5px;
  background-color: #f8f8f8;
  height: 40px;
`;
export const StyledSelect = styled(Select)`
  border-radius: 5px;
  background-color: #f8f8f8;
  height: 40px;
  .ant-select-selector {
    background-color: #f8f8f8 !important;
  }
`;
export const FormPartHeader = styled.div`
  background-color: #78486a;
  color: #fff;
  font-family: Poppins;
  font-size: 44px;
  font-style: normal;
  font-weight: 400;
  line-height: 56px;
  letter-spacing: -0.88px;
  text-align: left;
  margin-bottom: 20px;
`;
