import {
  DescriptionContainer,
  Header,
  HeaderDescription,
  HeaderTitle,
  TermsButton,
} from "./style";
const RegistrationSuccess = () => {
  return (
    <>
      <Header>
        <div style={{ marginLeft: "5%" }}>
          <img
            src="iptc-logo.jpg"
            alt="logo"
            width="166.56px"
            height="69.72px"
          />
        </div>
        <TermsButton>Terms & Conditions</TermsButton>
      </Header>
      <DescriptionContainer>
        <HeaderTitle>Registration</HeaderTitle>
        <HeaderDescription>
          Congratulations, your registration has been successfully processed.
          The IPTC Management Team extends its gratitude, and please anticipate
          a confirmation email shortly. We eagerly await the opportunity to
          welcome you at IPTC.
        </HeaderDescription>
      </DescriptionContainer>
    </>
  );
};
export default RegistrationSuccess;
