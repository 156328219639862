import { Footer, FooterItems } from "./style";
const FooterComponent = () => {
  return (
    <Footer>
      <div>
        <img
          src="../iptc-logo.jpg"
          alt="logo"
          width="166.56px"
          height="69.72px"
        />
        <FooterItems>
          <p>Privacy</p>
          <p>Terms& Condition</p>
          <p>Help Center</p>
        </FooterItems>
      </div>
    </Footer>
  );
};
export default FooterComponent;
