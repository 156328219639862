import { Fragment, useEffect, useState } from "react";
import HeaderComponent from "../shared/header";
import FooterComponent from "../shared/footer";
import HeaderListsComponent from "../shared/header-lists";
import { Col, Row, Tag, Avatar } from "antd";
import { Input, Select } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import customFetch from "../../services/api";
import { DivCenter, ImageDiv, ImageTitle } from "../shared/style";
import { useNavigate } from "react-router-dom";
import { AddUserButton, StyledTable, ExcelButton } from "../dashboard/style";
import { BackDiv } from "./style";
const { Search } = Input;

const Guestes = () => {
  const [loading, setLoading] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [pagination, setPagination] = useState(1);
  const navigate = useNavigate();
  const onSearch = () => {};
  const filterBy = () => {};
  const handleBack = () => {
    navigate("/dashboard");
  };
  const handleAddNewUser = () => {
    navigate("/registration");
  };
  const handleRowClick = (record) => {
    navigate("/guests/" + record.id);
  };

  useEffect(() => {
    const fetchParticipants = async () => {
      setLoading(true);
      const response = await customFetch("/participants");
      if (response.success) {
        setParticipants(response.data);
      }
      setLoading(false);
    };
    fetchParticipants();
  }, []);
  const tableColumns = [
    {
      title: "User Name",
      dataIndex: "username",
      render: (_, elm) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar src="https://api.dicebear.com/7.x/miniavs/svg?seed=1" />
          <p style={{ marginLeft: "8px" }}>
            {elm.first_name} {elm.middle_name}
          </p>
        </div>
      ),
      key: "username",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Nationality",
      dataIndex: "nationality",
      key: "nationality",
    },
    {
      title: "Job Title",
      dataIndex: "job_title",
      key: "job_title",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "Type User",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, elm) => {
        return elm.status === "Pending" ? (
          <Tag color="blue">Pending</Tag>
        ) : elm.status === "Accepted" ? (
          <Tag color="green">Accepted</Tag>
        ) : (
          <Tag color="magenta">Rejected</Tag>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: () => <MoreOutlined />,
    },
  ];
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const scrollConfig = windowWidth < 1200 ? { x: 1200 } : {};
  return (
    <Fragment>
      <HeaderComponent />
      <HeaderListsComponent />
      <ImageDiv>
        <ImageTitle>Guestes</ImageTitle>
      </ImageDiv>

      <div style={{ margin: "78px 5%" }}>
        <BackDiv onClick={handleBack}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="24"
            viewBox="0 0 32 24"
            fill="none"
          >
            <path
              d="M4.25 12.2744L30.5 12.2744"
              stroke="#141414"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10.2998 18.2988L4.24981 12.2748L10.2998 6.24976"
              stroke="#141414"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          {"  "}{" "}
          <div style={{ marginLeft: "10px", marginTop: "3px" }}>Back</div>
        </BackDiv>
        <div>
          <Row justify="space-between" align="middle">
            <Col xs={24} md={2}>
              <h3>Guests</h3>
            </Col>
            <Col xs={24} md={14}>
              <Row justify="center" align="center">
                <Col xs={24} md={6} style={{ marginRight: "10px" }}>
                  <Search placeholder="input search text" onSearch={onSearch} />
                </Col>
                <Col xs={24} md={6} style={{ marginRight: "10px" }}>
                  <Select
                    placeholder="Filter By"
                    optionFilterProp="children"
                    onChange={filterBy}
                    style={{ width: "100%", margin: "0px 2px " }}
                    options={[
                      {
                        value: "first name",
                        label: "first name",
                      },
                      {
                        value: "last name",
                        label: "last name",
                      },
                    ]}
                  />
                </Col>
                <Col xs={12} md={4}>
                  <div
                    style={{
                      backgroundColor: "rgba(37, 99, 221, 0.04)",
                      display: "flex",
                      height: "30px",
                      margin: "2px",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={handleAddNewUser}
                  >
                    <img alt="" src="plus.jpg" width="20px" height="20px" />
                    <AddUserButton>Add New User</AddUserButton>
                  </div>
                </Col>
                <Col xs={12} md={4}>
                  <div
                    style={{
                      backgroundColor: "rgba(37, 99, 221, 0.04)",
                      display: "flex",
                      height: "30px",
                      margin: "2px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img alt="" src="excel.jpg" height="20px" />
                    <ExcelButton sty>Excel</ExcelButton>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <StyledTable
            dataSource={participants}
            columns={tableColumns}
            pagination={pagination}
            scroll={scrollConfig}
            loading={loading}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  handleRowClick(record);
                }, // click row
              };
            }}
          />
        </div>
      </div>
      <FooterComponent />
    </Fragment>
  );
};
export default Guestes;
