import styled from "styled-components";
export const FooterItems = styled.div`
  display: flex;
  justify-content: space-between;
  width: 230px;
  color: var(--Gray-Gray-700, #5e6278);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 30px;
`;
export const Footer = styled.div`
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  border-top: 1px solid #f8f8f8;
  padding-top: 30px;
`;
export const Header = styled.div`
  height: 155px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 767px) {
    display: block;
    justify-content: center;
  }
`;
export const StyledNumber = styled.p`
  display: flex;
  padding: 8px 14px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 9px;
  background: #78a22e;
`;
export const HeaderItems = styled.div`
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: 5%;
`;
export const HeaderLists = styled.div`
  background-color: #78486a;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  @media (max-width: 767px) {
    display: block;
    justify-content: center;
  }
`;
export const HeaderListsItem = styled.p`
  color: #d8d8e5;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  align-items: center;
  margin-right: 40px;
`;
export const StatiscsCard = styled.div`
  border-radius: 16px;
  border: 1px solid #efefef;
  background: #fff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.02),
    0px 6px 10px 0px rgba(177, 177, 177, 0.08);
  padding: 10px;
  text-align: left;
  margin: 0px 10px 10px 0px;
`;
export const Title = styled.p`
  color: #626262;
  font-family: SF Pro Text;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-right: 5px;
`;
export const Value = styled.p`
  color: var(--78486-a, #78486a);
  font-family: SF Pro Text;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -1.28px;
`;
export const DivCenter = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;
export const ImageDiv = styled.div`
  width: 100%;
  height: 124px;
  background-image: url(../img-header-1.jpeg);
  background-size: cover;
  position: relative;
`;
export const ImageTitle = styled.p`
  color: #fff;
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 100% */
  letter-spacing: -0.22px;
  position: absolute;
  top: 30px;
  left: 50px;
`;
export const ProfCard = styled.div`
  padding: 35px;
  border-radius: 16px;
  border: 1px solid #efefef;
  background: #fff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.02),
    0px 6px 10px 0px rgba(177, 177, 177, 0.08);
  text-align: left;
  margin: 5%;
`;
export const ProfCardLabel = styled.p`
  color: #141414;
  font-family: SF Pro Text;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
export const ProfCardValue = styled.p`
  color: #78486a;
  font-family: SF Pro Text;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
