import { HeaderLists, HeaderListsItem } from "../shared/style";

const HeaderListsComponent = () => {
  return (
    <HeaderLists>
      <HeaderListsItem>Dashboard</HeaderListsItem>
      <HeaderListsItem>Guest lists</HeaderListsItem>
      <HeaderListsItem>Invitations</HeaderListsItem>
      <HeaderListsItem>Seating Plan</HeaderListsItem>
      <HeaderListsItem>Meeting Room</HeaderListsItem>
      <HeaderListsItem>Help</HeaderListsItem>
    </HeaderLists>
  );
};
export default HeaderListsComponent;
