import { Fragment, useEffect, useState } from "react";
import HeaderComponent from "../shared/header";
import FooterComponent from "../shared/footer";
import HeaderListsComponent from "../shared/header-lists";
import { Col, Row, Tag, Avatar } from "antd";
import StatiscsCardComponent from "../shared/statiscs-card";
import {
  AddUserButton,
  StyledTable,
  ExcelButton,
  More,
  ItemTitle,
  ItemNumber,
} from "./style";
import { Input, Select } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import customFetch from "../../services/api";
import { DivCenter, ImageDiv, ImageTitle } from "../shared/style";
import { useNavigate } from "react-router-dom";
const { Search } = Input;

const Dashboard = () => {
  const [loading, setLoading] = useState([]);
  const [participants, setParticipants] = useState([]);
  const navigate = useNavigate();
  const onSearch = () => {};
  const filterBy = () => {};
  const handleGuests = () => {
    navigate("/guests");
  };
  const handleAddNewUser = () => {
    navigate("/registration");
  };
  const handleRowClick = (record) => {
    navigate("/guests/" + record.id);
  };
  useEffect(() => {
    const fetchParticipants = async () => {
      setLoading(true);
      const response = await customFetch("/participants");
      if (response.success) {
        setParticipants(response.data.slice(0, 4));
      }
      setLoading(false);
    };
    fetchParticipants();
  }, []);
  const tableColumns = [
    {
      title: "User Name",
      dataIndex: "username",
      render: (_, elm) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar src="https://api.dicebear.com/7.x/miniavs/svg?seed=1" />
          <p style={{ marginLeft: "8px" }}>
            {elm.first_name} {elm.middle_name}
          </p>
        </div>
      ),
      key: "username",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Nationality",
      dataIndex: "nationality",
      key: "nationality",
    },
    {
      title: "Job Title",
      dataIndex: "job_title",
      key: "job_title",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "Type User",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, elm) => {
        return elm.status === "Pending" ? (
          <Tag color="blue">Pending</Tag>
        ) : elm.status === "Accepted" ? (
          <Tag color="green">Accepted</Tag>
        ) : (
          <Tag color="magenta">Rejected</Tag>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: () => <MoreOutlined />,
    },
  ];
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const scrollConfig = windowWidth < 1200 ? { x: 1200 } : {};

  return (
    <Fragment>
      <HeaderComponent />
      <HeaderListsComponent />
      <ImageDiv>
        <ImageTitle>Dashboard</ImageTitle>
      </ImageDiv>
      <div style={{ margin: "78px 5%" }}>
        <Row>
          <Col xs={24} sm={24} md={12}>
            <Row>
              <Col xs={24} sm={12} md={10}>
                <StatiscsCardComponent title="Checked-In" value="21,640" />
              </Col>
              <Col xs={24} sm={12} md={10}>
                <StatiscsCardComponent title="Apologized" value="15,640" />
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={12} md={10}>
                <StatiscsCardComponent title="No Response" value="10,640" />
              </Col>
              <Col xs={24} sm={12} md={10}>
                <StatiscsCardComponent title="Waiting" value="5,000" />
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Row style={{ border: "1px solid #EFEFEF", borderRadius: "16px" }}>
              <Col sm={24} md={12}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="325"
                  height="325"
                  viewBox="0 0 325 325"
                  fill="none"
                >
                  <g filter="url(#filter0_d_198_1539)">
                    <circle cx="162.5" cy="162.499" r="75.8359" fill="white" />
                  </g>
                  <text
                    x="50%"
                    y="45%"
                    dominant-baseline="middle"
                    text-anchor="middle"
                    font-size="20"
                    font-weight="bold"
                    fill="black"
                  >
                    21,640
                  </text>
                  <text
                    x="50%"
                    y="55%"
                    dominant-baseline="middle"
                    text-anchor="middle"
                    font-size="10"
                    font-weight="bold"
                    fill="black"
                  >
                    Total Guests{" "}
                  </text>
                  <defs>
                    <filter
                      id="filter0_d_198_1539"
                      x="0.464066"
                      y="0.463577"
                      width="324.072"
                      height="324.072"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feMorphology
                        radius="1"
                        operator="dilate"
                        in="SourceAlpha"
                        result="effect1_dropShadow_198_1539"
                      />
                      <feOffset />
                      <feGaussianBlur stdDeviation="42.6" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.0331858 0 0 0 0 0.00824653 0 0 0 0 0.104167 0 0 0 0.1 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_198_1539"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_198_1539"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>
              </Col>
              <Col sm={24} md={12}>
                <Row style={{ margin: "43px 0px" }}>
                  <Col sm={12}>
                    <ItemTitle>Checked-In</ItemTitle>{" "}
                  </Col>
                  <Col sm={12}>
                    <ItemNumber background="rgba(98, 166, 246, 0.10)">
                      30%
                    </ItemNumber>
                  </Col>
                </Row>
                <Row style={{ margin: "43px 0px" }}>
                  <Col sm={12}>
                    <ItemTitle>Apologized</ItemTitle>{" "}
                  </Col>
                  <Col sm={12}>
                    <ItemNumber background="rgba(240, 122, 71, 0.10)">
                      40%
                    </ItemNumber>
                  </Col>
                </Row>
                <Row style={{ margin: "43px 0px" }}>
                  <Col sm={12}>
                    <ItemTitle>No Response</ItemTitle>{" "}
                  </Col>
                  <Col sm={12}>
                    <ItemNumber background="rgba(120, 72, 106, 0.10)">
                      30%
                    </ItemNumber>
                  </Col>
                </Row>
                <Row style={{ margin: "43px 0px" }}>
                  <Col sm={12}>
                    <ItemTitle>Waiting</ItemTitle>{" "}
                  </Col>
                  <Col sm={12}>
                    <ItemNumber background="rgba(129, 165, 62, 0.10)">
                      10%
                    </ItemNumber>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <div style={{ border: "1px solid #EFEFEF", borderRadius: "16px" }}>
          <Row justify="space-between" align="middle">
            <Col xs={24} md={2}>
              <h3>Guests</h3>
            </Col>
            <Col xs={24} md={14}>
              <Row justify="center" align="center">
                <Col xs={24} md={6} style={{ marginRight: "10px" }}>
                  <Search placeholder="input search text" onSearch={onSearch} />
                </Col>
                <Col xs={24} md={6} style={{ marginRight: "10px" }}>
                  <Select
                    placeholder="Filter By"
                    optionFilterProp="children"
                    onChange={filterBy}
                    style={{ width: "100%", margin: "0px 2px " }}
                    options={[
                      {
                        value: "first name",
                        label: "first name",
                      },
                      {
                        value: "last name",
                        label: "last name",
                      },
                    ]}
                  />
                </Col>
                <Col xs={12} md={4}>
                  <div
                    style={{
                      backgroundColor: "rgba(37, 99, 221, 0.04)",
                      display: "flex",
                      height: "30px",
                      margin: "2px",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer", // Set cursor to pointer
                    }}
                    onClick={handleAddNewUser}
                  >
                    <img alt="" src="plus.jpg" width="20px" height="20px" />
                    <AddUserButton>Add New User</AddUserButton>
                  </div>
                </Col>
                <Col xs={12} md={4}>
                  <div
                    style={{
                      backgroundColor: "rgba(37, 99, 221, 0.04)",
                      display: "flex",
                      height: "30px",
                      margin: "2px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img alt="" src="excel.jpg" height="20px" />
                    <ExcelButton sty>Excel</ExcelButton>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <StyledTable
            dataSource={participants}
            columns={tableColumns}
            pagination={false}
            scroll={scrollConfig}
            loading={loading}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  handleRowClick(record);
                }, // click row
              };
            }}
          />
        </div>
        <More onClick={handleGuests}>
          <DivCenter>
            <p>More</p>{" "}
            <img alt="" src="Arrow - Right.jpg" width="24px" height="24px" />
          </DivCenter>
        </More>
        <FooterComponent />
      </div>
    </Fragment>
  );
};
export default Dashboard;
