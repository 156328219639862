import styled from "styled-components";
export const SignInPar = styled.h3`
  color: var(--Gray-Gray-900, #181c32);
  text-align: center;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;
export const SignInNotes = styled.p`
  color: var(--Gray-Gray-500, #a1a5b7);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 100% */
`;
export const SignInButton = styled.button`
  width: 100%;
  height: 40px;
  text-align: center;
  background: var(--78-a-22-e, #78a22e);
  border: none;
  color: #fff;
  border-radius: 6px;
`;
export const AlreadyHave = styled.p`
  color: var(--Gray-Gray-500, #a1a5b7);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
`;
export const LoginTerms = styled.div`
  color: var(--231-f-20, #231f20);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 14px;
  display: flex;
  justify-content: space-around;
`;
export const LoginContainer = styled.div`
  max-width: 390px;
  margin: 10% auto;
`;
