import { FormButton, FormPartHeader, StyledInput, StyledSelect } from "./style";
import { Form, Row, Col, DatePicker, Select, Radio, TimePicker } from "antd";
import * as countriesList from "countries-list";
import { PlusCircleOutlined } from "@ant-design/icons";
import { useState } from "react";
const { Option } = Select;

const countryOptions = Object.keys(countriesList.countries).map((code) => (
  <Option key={code} value={code}>
    {countriesList.countries[code].name}
  </Option>
));

const FormComponent = ({ onFinish, form }) => {
  const [onBehaveValue, setOnBehaveValue] = useState("No");
  const [additionalRows, setAdditionalRows] = useState([{}]);

  const handleAddRow = () => {
    setAdditionalRows([...additionalRows, {}]);
  };
  return (
    <Form name="myForm" onFinish={onFinish} form={form}>
      {additionalRows.map((row, index) => (
        <>
          <Row>
            <Col xs={24}>
              <FormPartHeader>Personal Details</FormPartHeader>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={12}>
              <Form.Item
                name={`title#${index}`}
                rules={[
                  {
                    required: true,
                    message: "Please enter your title.",
                  },
                  { max: 50, message: "Maximum 50 characters allowed." },
                ]}
                style={{ marginRight: "5px" }}
              >
                <StyledInput placeholder="Title *" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name={`first_name#${index}`}
                rules={[
                  {
                    required: true,
                    message: "Please enter your first name.",
                  },
                  { max: 50, message: "Maximum 50 characters allowed." },
                ]}
                style={{ marginRight: "5px" }}
              >
                <StyledInput placeholder="First Name *" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={12}>
              <Form.Item
                name={`middle_name#${index}`}
                rules={[
                  {
                    required: true,
                    message: "Please enter your Middle Name.",
                  },
                  { max: 50, message: "Maximum 50 characters allowed." },
                ]}
                style={{ marginRight: "5px" }}
              >
                <StyledInput placeholder="Middle Name *" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name={`family_name#${index}`}
                rules={[
                  {
                    required: true,
                    message: "Please enter your Family name.",
                  },
                  { max: 50, message: "Maximum 50 characters allowed." },
                ]}
                style={{ marginRight: "5px" }}
              >
                <StyledInput placeholder="Family Name *" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={12}>
              <Form.Item
                name={`nationality#${index}`}
                rules={[
                  {
                    required: true,
                    message: "Please select your Nationality.",
                  },
                ]}
                style={{ textAlign: "left", marginRight: "5px" }}
              >
                <StyledInput placeholder="Nationality Name *" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name={`gender#${index}`}
                rules={[
                  { required: true, message: "Please select your gender." },
                ]}
                style={{ textAlign: "left", marginRight: "5px" }}
              >
                <StyledSelect placeholder="Gender*">
                  <Option value="Male">Male</Option>
                  <Option value="Female">Female</Option>
                </StyledSelect>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={12}>
              <Form.Item
                name={`mobile#${index}`}
                rules={[
                  {
                    required: true,
                    message: "Please enter your mobile number.",
                  },
                  {
                    pattern: /^[0-9]*$/,
                    message: "Please enter a valid mobile number.",
                  },
                ]}
                style={{ marginRight: "5px" }}
              >
                <StyledInput type="tel" placeholder="Mobile*" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name={`country_residence#${index}`}
                rules={[
                  {
                    required: true,
                    message: "Please select your country of residence.",
                  },
                ]}
                style={{ textAlign: "left", marginRight: "5px" }}
              >
                <StyledSelect showSearch placeholder="Country Of Residence*">
                  {countryOptions}
                </StyledSelect>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={12}>
              <Form.Item
                name={`email#${index}`}
                rules={[
                  {
                    required: true,
                    message: "Please enter your email address.",
                  },
                  {
                    type: "email",
                    message: "Please enter a valid email address.",
                  },
                ]}
                style={{ marginRight: "5px" }}
              >
                <StyledInput type="email" placeholder="Email*" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name={`confirm_email#${index}`}
                rules={[
                  {
                    required: true,
                    message: "Please enter your confirm email address.",
                  },
                  {
                    type: "email",
                    message: "Please enter a valid email address.",
                  },
                ]}
                style={{ marginRight: "5px" }}
              >
                <StyledInput type="email" placeholder="Confirm Email*" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={18}>
              <Form.Item
                label="Are you completing this registration on behalf of someone else?"
                name="onBehalf"
                initialValue="No"
              >
                <Radio.Group
                  style={{ display: "flex" }}
                  onChange={(e) => setOnBehaveValue(e.target.value)}
                >
                  <Radio value="Yes">Yes</Radio>
                  <Radio value="No">No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={24} md={6}>
              {onBehaveValue === "Yes" && (
                <div
                  style={{
                    backgroundColor: "rgba(37, 99, 221, 0.04)",
                    display: "flex",
                    height: "30px",
                    margin: "2px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={handleAddRow}
                >
                  <img alt="" src="plus.jpg" width="20px" height="20px" />
                  <p>Add a nother user</p>
                </div>
              )}
            </Col>
          </Row>
        </>
      ))}

      {/* /////// */}
      <Row>
        <Col xs={24}>
          <FormPartHeader>Organization Details</FormPartHeader>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={12}>
          <Form.Item
            name="company_name"
            rules={[
              {
                required: true,
                message: "Please enter your company name.",
              },
              {
                message: "Please enter a valid company name.",
              },
            ]}
            style={{ marginRight: "5px" }}
          >
            <StyledInput placeholder="Company Name*" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name="job_title"
            rules={[
              {
                required: true,
                message: "Please enter your job title.",
              },
              {
                message: "Please enter a valid job title.",
              },
            ]}
            style={{ marginRight: "5px" }}
          >
            <StyledInput placeholder="Job Title*" />
          </Form.Item>
        </Col>
      </Row>
      {/* /////// */}
      <Row>
        <Col xs={24}>
          <FormPartHeader>Travel Details (Optional)</FormPartHeader>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={12}>
          <Form.Item
            name="has_visa"
            style={{ textAlign: "left", marginRight: "5px" }}
          >
            <StyledSelect placeholder="Do you need Visa Assistance?">
              <Option key="Yes" value="Yes">
                Yes
              </Option>
              <Option key="No" value="No">
                No
              </Option>
            </StyledSelect>
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item name="flight_number" style={{ marginRight: "5px" }}>
            <StyledInput placeholder="Flight #*" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={12}>
          <Form.Item name="arrival_date" style={{ marginRight: "5px" }}>
            <DatePicker
              style={{
                width: "100%",
                backgroundColor: "#f8f8f8",
                height: "40px",
              }}
              placeholder="Arrival Date*"
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item name="arrival_time" style={{ marginRight: "5px" }}>
            <TimePicker
              style={{
                width: "100%",
                backgroundColor: "#f8f8f8",
                height: "40px",
              }}
              placeholder="Arrival time*"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={12}>
          <Form.Item name="arrival_airport">
            <StyledInput placeholder="Arrival Airport #*" />
          </Form.Item>
        </Col>
      </Row>
      {/* /////// */}
      <Row>
        <Col xs={24}>
          <FormPartHeader>Accommodation Details (Optional)</FormPartHeader>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={12}>
          <Form.Item name="hotel" style={{ marginRight: "5px" }}>
            <StyledInput placeholder="Hotel*" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item name="city" style={{ marginRight: "5px" }}>
            <StyledInput placeholder="City Name*" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={12}>
          <Form.Item name="stay_duration" style={{ marginRight: "5px" }}>
            <StyledInput placeholder="Stay Duration*" />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <FormButton>Submit</FormButton>
      </Form.Item>
    </Form>
  );
};
export default FormComponent;
