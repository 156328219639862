import { Checkbox, Col, Form, Input, Row } from "antd";
import {
  SignInNotes,
  SignInPar,
  SignInButton,
  AlreadyHave,
  LoginTerms,
  LoginContainer,
} from "./style";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const onFinish = (values) => {
    const { email } = values;
    if (email === "admin@admin.com") {
      navigate("/dashboard");
    } else {
      navigate("/registration");
    }
  };

  return (
    <>
      <Row style={{ height: "100vh" }}>
        <Col xs={24} sm={24} md={12}>
          <LoginContainer>
            <img
              alt=""
              src="iptc-logo.jpg"
              width="166.56px"
              height="69.721px"
            />
            <SignInPar>Sign In for an account</SignInPar>
            <SignInNotes>Fill your Data carefully!</SignInNotes>
            <Form name="myForm" onFinish={onFinish} form={form}>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please enter your email.",
                  },
                  { max: 50, message: "Maximum 50 characters allowed." },
                ]}
                style={{ marginRight: "5px" }}
              >
                <Input placeholder="Email *" />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please enter your password.",
                  },
                  { max: 50, message: "Maximum 50 characters allowed." },
                ]}
                style={{ marginRight: "5px" }}
              >
                <Input.Password
                  placeholder="Password *"
                  type="password"
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>
              <Form.Item>
                <Row>
                  <Col span={10}>
                    <Checkbox style={{ fontSize: "13px" }}>
                      I Accept the{" "}
                      <span style={{ color: "#78A22E" }}>Terms</span>
                    </Checkbox>
                  </Col>
                  <Col span={14} style={{ textAlign: "right" }}>
                    <a href="/forgot-password" style={{ color: "#78A22E" }}>
                      Forgot Password?
                    </a>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item>
                <SignInButton>Submit</SignInButton>
              </Form.Item>
            </Form>
            <AlreadyHave>
              Already have an Account?{" "}
              <span style={{ color: "#78A22E" }}> Sign in</span>
            </AlreadyHave>
            <LoginTerms>
              <p>Privacy</p>
              <p>Terms& Condition</p>
              <p>Help Center</p>
            </LoginTerms>
          </LoginContainer>
        </Col>
        <Col xs={0} sm={0} md={12}>
          <img alt="" src="login-cover.jpeg" width="100%" height="100%" />
        </Col>
      </Row>
    </>
  );
};
export default Login;
