import "./App.css";
import Registration from "./components/registration";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import RegistrationSuccess from "./components/registration/registration-success";
import Login from "./components/login";
import Dashboard from "./components/dashboard";
import Guests from "./components/guests";
import Guest from "./components/guests/guest";
function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/registration" element={<Registration />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/guests" element={<Guests />} />
          <Route path="/" element={<Login />} />
          <Route path="/guests/:id" element={<Guest />} />

          <Route
            path="/registration-success"
            element={<RegistrationSuccess />}
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
