// const BASE_URL = "http://127.0.0.1:8000/api";
const BASE_URL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_BASE_URL_LOCAL
    : process.env.REACT_APP_BASE_URL_PROD;

const customFetch = async (url, options = {}) => {
  console.log(BASE_URL);
  const response = await fetch(`${BASE_URL}${url}`, {
    method: options.method || "GET",
    headers: {
      "Content-Type": "application/json",
    },
    body: options.body ? JSON.stringify(options.body) : undefined,
  });

  return response.json();
};

// Example usage

export default customFetch;
